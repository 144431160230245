import { template as template_889dcc62aad24fcc91b0f5a7744108ce } from "@ember/template-compiler";
const FKText = template_889dcc62aad24fcc91b0f5a7744108ce(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
